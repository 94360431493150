'use client'

import { isServer } from '@tebuto/functions'
import { Router } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'

const siteID = 1
const trackerUrl = '//tr.adwert-it.de/'

export function MatomoProvider() {
    useEffect(() => {
        Router.events.on('routeChangeComplete', Matomo.trackView)
        return () => Router.events.off('routeChangeComplete', Matomo.trackView)
    }, [])

    return <></>
}

declare global {
    interface Window {
        _mtm: any[]
        _paq: any[]
    }
}

export namespace Matomo {
    const matomo = !isServer() ? window._paq || [] : []

    export function code() {
        return (
            <Script
                strategy="afterInteractive"
                // biome-ignore lint/security/noDangerouslySetInnerHtml: We dont care about this code here
                dangerouslySetInnerHTML={{
                    __html: `
                    var _paq = window._paq = window._paq || [];
                    _paq.push(['disableCookies']);
                    _paq.push(['trackPageView']);
                    _paq.push(['enableLinkTracking']);
                    (function() {
                    var u='${trackerUrl}';
                    _paq.push(['setTrackerUrl', u+'matomo.php']);
                    _paq.push(['setSiteId', ${siteID}]);
                    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
                    })();
                    `
                }}
            />
        )
    }

    export function trackView(url: string) {
        if (matomo) {
            matomo.push(['setCustomUrl', url])
            matomo.push(['trackPageView'])
        }
    }
}
